import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dictionary } from 'src/common-types';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root',
})
export class TaApiService {
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly httpClient: HttpClient) {}

  public getDictionary(dictionaryName: string) {
    const params = new HttpParams().set('dictionaryName', dictionaryName);

    return this.httpClient.get<Dictionary[]>(`${environment.api.taBaseUri}/Dictionary`, {
      params,
    });
  }

  public getExternalFieldsForEditExternalRole(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.api.taBaseUri}/ExternalFields`);
  }
}
