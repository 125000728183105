import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { SdiRole } from 'src/shared/models/user-roles.model';
import { CommonApiService } from '../../shared/services/api/common-api.service';
import { SdiApiProject } from '../models/sdi-api.model';
import { RouteDataService } from '../../shared/services/route-data.service';
import { SdiApiService } from './sdi-api.service';

@Injectable({
  providedIn: 'root',
})
export class SdiRolesService {
  private readonly refetch$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith(void {}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  readonly rights$ = this.user$.pipe(
    map(({ SdiUserRoles }) => SdiUserRoles ?? []),
    shareReplay(1),
  );

  readonly sdiReviewOnlyFields$ = this.sdiApiService.getSdiReviewOnlyFields().pipe(
    shareReplay(1),
  );

  readonly projectStatus$ = this.routeDataService.activeRouteData$.pipe(
    map(routeData => routeData['project'] as SdiApiProject),
    map(project => project?.ProjectStepId ?? null),
  );

  constructor(
    private readonly commonApiService: CommonApiService,
    private readonly routeDataService: RouteDataService,
    private readonly sdiApiService: SdiApiService,
  ) {}

  hasRole(role: SdiRole, companyId: number | null = null): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights?.some(right => {
        if (role === SdiRole.Review) {
          return right.RoleDefinitionId === role;
        } else {
          return (right.CompanyId === companyId && right.RoleDefinitionId === role);
        }
      })),
    );
  }

  refetch() {
    this.refetch$.next(void {});
  }
}
