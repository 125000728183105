<div class="home-container">
  <img class="home-logo" src="../assets/pidg-logo.svg" alt="PIDG Logo">

  <div class="home-loader" *ngIf="loading$ | async">
    <mat-spinner [diameter]="32"></mat-spinner>
  </div>

  <p i18n *ngIf="noAccess" class="home-no-access">
    Sorry, you do not have access to PIDG Compass.<br />
    Please contact your PIDG Compass administrator.
  </p>
</div>
