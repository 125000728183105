import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { PpmRole } from '../models/user-roles.model';
import { CommonApiService } from '../services/api/common-api.service';

@Injectable({
  providedIn: 'root',
})
export class PpmRolesService {
  private readonly refetch$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith(void {}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  readonly rights$ = this.user$.pipe(
    map(({ PpmUserRoles }) => PpmUserRoles ?? {}),
    shareReplay(1),
  );

  constructor(private readonly commonApiService: CommonApiService) {}

  hasRole(role: PpmRole, companyId: number): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights.some(right => right.CompanyId === companyId && right.RoleDefinitionId === role)),
    );
  }

  refetch() {
    this.refetch$.next(void {});
  }
}
